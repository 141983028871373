<template>
    <div class="container" v-cloak>
        <b-overlay :show="loading" rounded="sm" no-wrap z-index="1000">
            <template v-slot:overlay v-if="paidSuccessfully">
                <div class="text-center">
                    <i class="icon icon-check"></i>
                    <h3>Thank you!</h3>
                </div>
            </template>
        </b-overlay>
        <div class="row">
            <div class="col-12 p-3">
                <button class="btn btn-common btn-back" @click="back">
                    Back
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-3">
                <div
                    class="card-plan p-3"
                    v-bind:class="{
                        basic: plan === 'basic',
                        pro: plan === 'pro'
                    }"
                >
                    <h6 class="mb-4">
                        You are purchasing AutoTouch license of plan:
                    </h6>
                    <div
                        class="d-flex justify-content-between align-items-baseline py-3"
                    >
                        <h3 class="title">
                            {{ title }}
                        </h3>
                        <h3 class="price">
                            ${{ price / 100 }}
                            <span class="unit">/ Year</span>
                        </h3>
                    </div>

                    <div
                        class="d-flex justify-content-between align-items-center py-3"
                    >
                        <h5 class="period">Period:</h5>
                        <b-form-select
                            v-model="period"
                            :options="periods"
                            style="max-width: 150px;"
                        />
                    </div>

                    <div
                        class="d-flex justify-content-between align-items-center py-3"
                    >
                        <h5 class="quantity">Quantity:</h5>
                        <b-form-spinbutton
                            min="1"
                            max="100"
                            v-model="quantity"
                            inline
                        ></b-form-spinbutton>
                    </div>

                    <div
                        class="d-flex justify-content-start align-items-baseline"
                    >
                        <div class="font-weight-bold">
                            <span>Total:</span>
                            <span class="mx-2" style="font-size: 30px"
                                >$ {{ totalAmount }}</span
                            >
                            <span>USD</span>
                        </div>
                    </div>
                </div>
                <div class="mt-3 d-flex justify-content-center w-100">
                    <form
                        v-if="
                            globalSettings &&
                                globalSettings.paypal_payee_email_1
                        "
                        id="form_pay"
                        name="form_pay"
                        :action="config.paypal.pdt"
                        method="post"
                    >
                        <div style="display: none">
                            <input type="hidden" name="cmd" value="_xclick" />
                            <input
                                type="hidden"
                                name="business"
                                :value="globalSettings.paypal_payee_email_1"
                            />
                            <input
                                type="hidden"
                                name="item_name"
                                :value="itemName"
                            />
                            <input
                                type="hidden"
                                name="item_number"
                                :value="quantity"
                            />
                            <input
                                type="hidden"
                                name="amount"
                                :value="totalAmount"
                            />
                            <input
                                type="hidden"
                                name="currency_code"
                                value="USD"
                            />
                            <input
                                type="hidden"
                                name="return"
                                :value="config.paypal.returnUrl"
                            />
                            <input
                                type="hidden"
                                name="notify_url"
                                :value="config.paypal.notifyUrl"
                            />
                            <input
                                type="hidden"
                                name="invoice"
                                :value="invoice"
                            />
                            <input
                                type="hidden"
                                name="custom"
                                :value="`SN:${plan}:${deviceId}:${period}`"
                            />
                            <input type="hidden" name="lc" value="EN" />
                        </div>
                        <button type="submit" class="btn btn-common btn-paypal">
                            <span>Pay with</span>
                            <img src="/paypal.png" alt="" />
                        </button>
                    </form>
                </div>
                <!--
                <div class="mt-3 d-flex justify-content-center">
                    <div ref="oftenpay" class="oftenpay-button-container">
                        <button
                            class="btn btn-common btn-oftenpay"
                            @click="payWithOftenPay"
                        >
                            <span>Pay with</span>
                            <img src="/oftenpay.png" alt="" />
                        </button>
                    </div>
                </div>
                <div class="mt-3 d-flex justify-content-center">
                    <div ref="trc20usdt" class="trc20usdt-button-container">
                        <button
                            class="btn btn-common btn-trc20usdt"
                            @click="payWithTRC20USDT"
                        >
                            <span>Pay with</span>
                            <img src="/trx.png" alt="TRX" class="mr-2" />
                            <img src="/usdt.png" alt="USDT" />
                            <span class="trc20-usdt-text">TRC20 USDT</span>
                        </button>
                    </div>
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
import config from "../../config";
import { getProductPrice, getProductTitle } from "../helper";
import axios from "axios";

export default {
    name: "Purchase",
    data() {
        return {
            lang: this.$store.state.language,
            deviceId: this.$store.state.currentDeviceId,

            plan: null,
            period: 1,
            quantity: 1,
            config: config,

            loading: false,
            paidSuccessfully: false,

            globalSettings: null,

            periods: [
                { value: 1, text: "1 Year" },
                { value: 2, text: "2 Years" },
                { value: 3, text: "3 Years" },
                { value: 4, text: "4 Years" },
                { value: 5, text: "5 Years" },
                { value: 6, text: "6 Years" },
                { value: 7, text: "7 Years" },
                { value: 8, text: "8 Years" },
                { value: 9, text: "9 Years" },
                { value: 10, text: "Lifetime" }
            ]
        };
    },
    mounted: function() {
        this.plan = this.$route.params.plan;
    },
    created: function() {
        this.getGlobalSettings();
    },
    computed: {
        price: function() {
            return getProductPrice(this.plan);
        },
        title: function() {
            return getProductTitle(this.plan);
        },
        totalAmount: function() {
            return (this.price * this.period * this.quantity) / 100;
        },
        invoice: function() {
            let r = Math.random()
                .toString(36)
                .slice(2, 7);
            return `${this.deviceId}-${r}`;
        },
        itemName: function() {
            if (this.period < 10) {
                return `AutoTouch ${this.title} ${this.period} year(s) license ${this.quantity} unit(s)`;
            }
            return `AutoTouch ${this.title} lifetime license ${this.quantity} unit(s)`;
        }
    },
    methods: {
        back() {
            this.$router.go(-1);
        },
        setLoaded() {
            this.loading = false;
            //   this.renderPayPalButton();
        },
        getGlobalSettings() {
            const self = this;

            self.loading = true;

            const url = `${config.server}/api/v1/global_settings`;

            axios
                .get(url)
                .then(function(response) {
                    self.globalSettings = response.data;
                })
                .catch(function(error) {
                    alert(error.response.data);
                })
                .finally(function() {
                    self.queried = true;
                    self.loading = false;
                });
        },
        payWithOftenPay() {
            const self = this;

            self.loading = true;

            const url = `${config.server}/api/v1/oftenpay/orders`;

            axios
                .post(url, {
                    plan: self.plan.toUpperCase(),
                    quantity: self.quantity
                })
                .then(function(response) {
                    window.location = response.data;
                })
                .catch(function(error) {
                    alert(error.response.data);
                })
                .finally(function() {
                    self.queried = true;
                    self.loading = false;
                });
        },
        payWithTRC20USDT() {
            const self = this;

            self.loading = true;

            const url = `${config.server}/api/v1/tron/orders`;

            axios
                .post(url, {
                    plan: self.plan.toUpperCase(),
                    quantity: self.quantity,
                    period: self.period
                })
                .then(function(response) {
                    window.location = response.data;
                })
                .catch(function(error) {
                    alert(error.response.data);
                })
                .finally(function() {
                    self.queried = true;
                    self.loading = false;
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.period,
.quantity {
    font-size: 24px;
    font-weight: 600;
}

.card-plan {
    min-height: 50px !important;
}

.form-pay {
    width: 100%;
}

.btn-paypal {
    color: #333;
    border-radius: 30px;
    width: 300px;
    background-color: #fec339;
    box-shadow: #cf9c23 1px 1px 1px;

    > span {
        font-size: 18px;
        font-weight: 500;
        padding-right: 10px;
        font-family: HelveticaNeue-CondensedBold;
    }

    > img {
        width: 120px;
    }
}

.btn-oftenpay {
    font-size: 1.1rem;
    width: 300px;
    color: #eee;
    border-radius: 30px;
    border: none;
    background: linear-gradient(172.83deg, #020664 1.44%, #c400bd 48.32%);
    box-shadow: #8c0387 1px 1px 1px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > span {
        font-size: 18px;
        font-weight: 500;
        padding-right: 10px;
        font-family: HelveticaNeue-CondensedBold;
    }

    > img {
        width: 120px;
    }
}

.btn-trc20usdt {
    font-size: 1.1rem;
    width: 300px;
    color: #eee;
    border-radius: 30px;
    border: none;
    background: linear-gradient(172.83deg, #FF0000 1.44%, #FF6600 48.32%);
    box-shadow: #CC5200 1px 1px 1px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > span {
        font-size: 18px;
        font-weight: 500;
        padding-right: 10px;
        font-family: HelveticaNeue-CondensedBold;
    }

    > img {
        height: 24px;
        width: 24px;
        margin-right: 5px;
    }

    .trc20-usdt-text {
        color: white;
        font-size: 22px;
        font-weight: bold;
        padding-left: 5px;
    }
}
</style>
